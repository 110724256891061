<template>
  <div>
    <v-row>
      <v-col
        sm="6"
        class="pt-0 pb-0"
      >
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dateValue"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateValue"
                label="Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                clearable
                hide-details
                outlined
                dense
                v-on="on"
                @click:prepend-inner="on.click"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dateValue"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDate(dateValue)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        sm="6"
        class="pt-0 pb-0"
      >
        <v-text-field
            v-model="timeValue"
            prepend-inner-icon="mdi-clock-time-four-outline"
            label="Time"
            readonly
            clearable
            hide-details
            outlined
            dense
            @click="openTimePicker"
        />
      </v-col>
    </v-row>
    <datetime
        ref="time"
        v-model="timeForPicker"
        type="time"
        input-class="d-none"
        class="theme-red"
        use12-hour
    />
  </div>
</template>
<script>
import { Datetime } from 'vue-datetime';
import moment from 'moment';

export default {
  name: 'DateTime',
  components: {
    Datetime,
  },

  props: {
    value: {
      type: String,
    },
  },

  data() {
    return {
      menu: false,
      date: null,
      time: null,
    };
  },

  computed: {
    /**
     * Split date from datetime value.
     *
     * @return {string}
     */
    splitDate() {
      if (!this.value) {
        return null;
      }
      const parts = this.value.split('T');
      if (parts.length === 2) {
        return parts[0];
      }
      return !this.value.includes(':') ? this.value : null;
    },

    /**
     * Split time from datetime value.
     *
     * @return {string}
     */
    splitTime() {
      if (!this.value) {
        return null;
      }
      const parts = this.value.split('T');
      if (parts.length === 2) {
        return parts[1];
      }
      return this.value.includes(':') ? this.value : null;
    },

    dateValue: {
      get() {
        return this.splitDate;
      },
      set(value) {
        let datetime = null;
        if (!this.value) {
          datetime = value;
        } else {
          const time = this.splitTime;
          if (value) {
            datetime = value + (time ? `T${time}` : '');
          } else {
            datetime = time;
          }
        }
        this.$emit('input', datetime);
      },
    },
    timeForPicker: {
      get() {
        const time = this.splitTime;
        return time ? `${moment().format('YYYY-MM-DD')}T${time}` : null;
      },
      set(value) {
        let time = null;
        let datetime = null;

        if (value) {
          time = moment(value).format('HH:mm:ss');
        }
        if (!this.value) {
          datetime = time;
        } else {
          const date = this.splitDate;
          if (date && time) {
            datetime = `${date}T${time}`;
          } else if (!date && time) {
            datetime = time;
          } else {
            datetime = date;
          }
        }

        this.$emit('input', datetime);
      },
    },
    timeValue: {
      get() {
        return this.splitTime
          ? moment.parseZone(`${moment().format('YYYY-MM-DD')}T${this.splitTime}`).format('hh:mm A')
          : null;
      },
      set(value) {
        if (!value) {
          this.timeForPicker = null;
        }
      },
    },
  },
  methods: {
    /**
     * Open timepicker
     *
     * @param {Object} event
     */
    openTimePicker(event) {
      this.$refs.time.open(event);
    },

    /**
     * Save date value.
     *
     * @param {String} date
     */
    saveDate(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
