<template>
  <div
      class="fields-container"
      v-bind:style="{ height: height + 'px' }"
  >
    <v-virtual-scroll
        :bench="3"
        :height="height"
        :items="fieldListComputed"
        item-height="64"
        width="100%"
    >
      <template v-slot:default="{ item, index }">
          <div
            v-bind:key="'field_' + index "
            class="field-row"
          >
            <div v-if="componentExists(item)">
              <component
                  v-bind:is="getComponentName(item)"
                  v-model="incidentData[item.field]"
                  :label="item.name"
                  :field="item"
              ></component>
            </div>
            <date-time
                v-else-if="item.type === 'datetime' && Array.isArray(item.field)"
                :date.sync="incidentData[item.field[0]]"
                :time.sync="incidentData[item.field[1]]"
                :label="item.name"
                :field="item"
            />
            <date-time-single-value
                v-else-if="item.type === 'datetime' && typeof item.field === 'string'"
                :datetime.sync="incidentData[item.field]"
                :label="item.name"
                :field="item"
            />
            <span v-else-if="item.type === 'gag'"></span>
            <span v-else>{{ item.name }} - Is not supported yet.</span>
          </div>
        </template>
      </v-virtual-scroll>
  </div>
</template>

<script>
import String from '@/components/IncidentView/EditControls/String';
import StringTextarea from '@/components/IncidentView/EditControls/StringTextarea';
import Select from '@/components/IncidentView/EditControls/Select';
import YesValue from '@/components/IncidentView/EditControls/YesValue';
import YesNoValue from '@/components/IncidentView/EditControls/YesNoValue';
import NumValue from '@/components/IncidentView/EditControls/NumValue';
import Date from '@/components/IncidentView/EditControls/Date';
import Time from '@/components/IncidentView/EditControls/Time';
import DateTime from '@/components/IncidentView/EditControls/DateTime';
import DateTimeSingleValue from '@/components/IncidentView/EditControls/DateTimeSingleValue';
import NoBill from '@/components/IncidentView/EditControls/NoBill';
import Joules from '@/components/IncidentView/EditControls/Joules';
import BloodGlucose from '@/components/IncidentView/EditControls/BloodGlucose';
import IncidentNumber from '@/components/IncidentView/EditControls/IncidentNumber';
import Zipcode from '@/components/IncidentView/EditControls/Zipcode';

export default {
  name: 'EditCategoryFields',

  components: {
    DateTime,
    DateTimeSingleValue,
    String,
    StringTextarea,
    Select,
    YesValue,
    YesNoValue,
    Time,
    Date,
    NumValue,
    NoBill,
    Joules,
    BloodGlucose,
    IncidentNumber,
    Zipcode,
  },
  props: {
    height: {
      type: Number,
      required: true,
    },
    fieldList: {
      type: Array,
      required: true,
    },
    incidentData: {
      type: Object,
    },
  },
  computed: {
    fieldListComputed() {
      const fields = [];
      this.fieldList.forEach(field => {
        fields.push(field);
        if (this.getComponentName(field) === 'StringTextarea') {
          for (let i = 1; i <= 2; i++) {
            fields.push({
              field: `text_area_gag_${i}`,
              type: 'gag',
              name: '',
            });
          }
        }
      });
      return fields;
    },
  },
  methods: {
    getComponentName(field) {
      const numTypes = [
        'decimal',
        'integer',
        'weight',
        'age',
      ];
      const typeControlMap = {
        string: 'String',
        date: 'Date',
        time: 'Time',
        joules: 'Joules',
        'blood-glucose': 'BloodGlucose',
        YesValue: 'YesValue',
        YesNoValue: 'YesNoValue',
      };

      if (field.type === 'string' && field.values) {
        return 'Select';
      }
      if (field.field === 'II_IncidentZipcode' || field.field === 'PI_PatientZipcode') {
        return 'Zipcode';
      }
      if (field.field === 'II_IncidentNumber') {
        return 'IncidentNumber';
      }
      if (field.field === 'PI_NoBill') {
        return 'NoBill';
      }
      if (field.field === 'comments_comments') {
        return 'StringTextarea';
      }
      if (numTypes.includes(field.type)) {
        return 'NumValue';
      }
      if (Object.prototype.hasOwnProperty.call(typeControlMap, field.type)) {
        return typeControlMap[field.type];
      }

      return null;
    },
    componentExists(field) {
      const componentName = this.getComponentName(field);
      if (!componentName) {
        return false;
      }
      return Object.prototype.hasOwnProperty.call(
        this.$options.components,
        componentName,
      );
    },
  },
};
</script>

<style lang="scss">
.fields-container {
  overflow-y: auto;
  overflow-x: hidden;
  .field-name {
    color: #9a9a9a;
  }
  .field-row {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
