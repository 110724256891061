<template>
  <div>
    <v-row>
      <v-col sm="6">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                :label="label"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDate(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="5">
        <v-text-field
            v-model="timeValue"
            prepend-inner-icon="mdi-clock-time-four-outline"
            label="Time"
            readonly
            :disabled="!date"
            @click="openTimePicker"
        />
      </v-col>
      <v-col sm="1">
        <v-btn
            v-if="date && time"
            icon
            class="mt-3"
            @click="date = null; timeForPicker = null;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <datetime
        ref="time"
        v-model="timeForPicker"
        type="time"
        input-class="d-none"
        class="theme-red"
        use12-hour
    />
  </div>
</template>
<script>
import { Datetime } from 'vue-datetime';
import moment from 'moment';

export default {
  name: 'DateTimeSingleValue',
  components: {
    Datetime,
  },

  props: {
    datetime: {
      type: String,
    },
    label: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menu: false,
      date: null,
      time: null,
    };
  },

  computed: {
    timeForPicker: {
      get() {
        return `${this.date}T${this.time}`;
      },
      set(value) {
        let newValue = null;
        if (value) {
          newValue = moment(value).format('HH:mm:ss');
        }
        this.time = newValue;
      },
    },
    timeValue() {
      return this.date && this.time
        ? moment(`${this.date} ${this.time}`).format('hh:mm A')
        : null;
    },
  },
  mounted() {
    if (this.datetime) {
      const datetime = moment(this.datetime);
      this.date = datetime.format('YYYY-MM-DD');
      this.time = datetime.format('HH:mm:ss');
    }
  },
  watch: {
    date() {
      this.updateDateTime();
    },
    time() {
      this.updateDateTime();
    },
  },
  methods: {
    updateDateTime() {
      if (this.date && this.time) {
        this.$emit('update:datetime', `${this.date} ${this.time}`);
      } else {
        this.$emit('update:datetime', null);
      }
    },

    /**
     * Handler of click by schedule date input.
     */
    openTimePicker(event) {
      if (!this.date) {
        return;
      }
      this.$refs.time.open(event);
    },

    saveDate(date) {
      this.$refs.menu.save(date);
      if (date && !this.time) {
        this.$emit('update:time', '00:00:00');
      }
    },
  },
};
</script>
