<template>
  <div>
    <template v-if="field.type === fieldTypes.STRING">
      <v-autocomplete
          v-if="field.values"
          v-model="condition.value"
          :items="selectItems"
          placeholder="Choose value"
          hide-details
          outlined
          dense
      />
      <v-text-field
          v-else
          v-model="condition.value"
          hide-details
          outlined
          dense
      />
    </template>
    <v-text-field
        v-else-if="numberTypes.includes(field.type)"
        v-model="condition.value"
        type="number"
        hide-details
        outlined
        dense
    />
    <div v-else-if="field.type === fieldTypes.BOOLEAN">
      <v-radio-group
          v-model="condition.value"
          row
          hide-details
          class="mt-0"
      >
        <v-radio
            label="Yes"
            :value="true"
        ></v-radio>
        <v-radio
            label="No"
            :value="false"
        ></v-radio>
      </v-radio-group>
    </div>
    <blood-glucose
      v-else-if="field.type === fieldTypes.BLOOD_GLUCOSE"
      v-model="condition.value"
    />
    <joules
        v-else-if="field.type === fieldTypes.JOULES"
        v-model="condition.value"
    />
    <date
        v-else-if="field.type === fieldTypes.DATE"
        v-model="condition.value"
    />
    <div v-else-if="field.type === fieldTypes.DATETIME">
      <DateTime v-model="condition.value"/>
    </div>
    <div v-else-if="field.type === fieldTypes.TIME">
      <Time v-model="condition.value"/>
    </div>
    <v-combobox
        v-else-if="field.type === fieldTypes.PATIENT_CITY_CODE"
        v-model="condition.value"
        :items="selectItems"
        :return-object="false"
        placeholder="City"
        hide-details
        outlined
        dense
    />
    <span v-else>Type "{{ field.type }}" is not supports</span>
  </div>
</template>
<script>
import _ from 'lodash';
import SEARCH_FIELD_TYPES from '@/enums/searchFieldTypes';
import Date from '@/components/Incidents/Criteria/Controls/Date';
import DateTime from '@/components/Incidents/Criteria/Controls/DateTime';
import Time from '@/components/Incidents/Criteria/Controls/Time';
import BloodGlucose from '@/components/Incidents/Criteria/Controls/BloodGlucose';
import Joules from '@/components/Incidents/Criteria/Controls/Joules';

export default {
  name: 'EqCriterion',
  components: {
    Joules, BloodGlucose, DateTime, Date, Time,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  watch: {
    condition: {
      handler() {
        this.$emit('update:condition', this.condition);
      },
      deep: true,
    },
  },
  computed: {
    /**
     * Get list of values for select control.
     *
     * @return {Array}
     */
    selectItems() {
      if (!this.field.values) {
        return null;
      }
      const items = [];
      _.forEach(this.field.values, (text, code) => {
        if (Array.isArray(text)) {
          items.push({ header: code });
          items.push({ divider: true });
          _.forEach(text, value => {
            items.push({
              text: value,
              value,
            });
          });
        } else {
          items.push({
            text: (this.field.displayValue ? `${code} - ` : '') + text,
            value: code,
          });
        }
      });
      if (this.field.historicalValues) {
        items.push({ header: 'Historical values:' });
        items.push({ divider: true });
        _.forEach(this.field.historicalValues, (text, code) => {
          items.push({
            text: `${code} - ${text}`,
            value: code,
          });
        });
      }
      return items;
    },
  },
  data() {
    return {
      value: null,
      fieldTypes: SEARCH_FIELD_TYPES,
      numberTypes: [
        SEARCH_FIELD_TYPES.INTEGER,
        SEARCH_FIELD_TYPES.DECIMAL,
      ],
    };
  },
};
</script>
