<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="textValue"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="textValue"
          prepend-inner-icon="mdi-calendar"
          readonly
          clearable
          hide-details
          outlined
          dense
          v-bind="attrs"
          v-on="on"
          @click:prepend-inner="on.click"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="textValue"
        no-title
        scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="$refs.menu.save(textValue)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'Date',
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
    };
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
